import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

// Components
import { ColorPickerComponent } from './components/color-picker/color-picker.component';
import { LoadingCardComponent } from './components/loading-card/loading-card.component';
import { AlertComponent } from './components/alert/alert.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { ImageUploadComponent } from './components/image-upload/image-upload.component';
import { PhotoCaptureComponent } from './components/photo-capture/photo-capture.component';
import { UsageDialogComponent } from './components/usage-dialog/usage-dialog.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { ConfirmationRequiredDialogComponent } from './components/confirmation-required-dialog/confirmation-required-dialog.component';
import { TrueFalseTickIconComponent } from './components/true-false-tick-icon/true-false-tick-icon.component';
import { DateInputComponent } from './components/date-input/date-input.component';
import { DialogComponent } from './components/dialog/dialog/dialog.component';
import { DialogFieldComponent } from './components/dialog/dialog-field/dialog-field.component';
import { DialogFieldInformationComponent } from './components/dialog/dialog-field-information/dialog-field-information.component';
import { PagingNavigationComponent } from './components/paging-navigation/paging-navigation.component';
import { CardContainerComponent } from './components/card-container/card-container.component';
import { WarningContainerComponent } from './components/warning-container/warning-container.component';
import { NoInformationMessageComponent } from './components/no-information-message/no-information-message.component';
import { SectionWrapperComponent } from './components/section-wrapper/section-wrapper.component';
import { InformationDialogComponent } from './components/information-dialog/information-dialog.component';
import { SupportTextComponent } from './components/support-text/support-text.component';
import { AdviceTextComponent } from './components/advice-text/advice-text.component';
import { PageInformationComponent } from './components/page-information-text/page-information-text.component';
import { ImagePopupComponent } from './components/image-popup/image-popup.component';
import { SecureImageComponent } from './components/secure-image/secure-image.component';
import { SecureBackgroundImageComponent } from './components/secure-background-image/secure-background-image.component';
import { ImageCardComponent } from './components/image-card/image-card.component';
import { FileUploadDialogComponent } from './components/file-upload-dialog/file-upload-dialog.component';
import { DtoUploadDialogComponent } from 'app/shared/components/dto-upload-dialog/dto-upload-dialog.component';
import { SignatureDialogComponent } from './components/signature-dialog/signature-dialog.component';
import { CanvasComponent } from './components/canvas/canvas.component';
import { ActivityLogComponent } from './components/activity-log/activity-log.component';
import { CircleProgressComponent } from './components/circle-progress/circle-progress.component';
import { SearchableInputComponent } from './components/searchable-input/searchable-input.component';
import { SearchableMultiSelectInputComponent } from './components/searchable-multi-select-input/searchable-multi-select-input.component';
import { SessionTimeoutDialogComponent } from './components/session-timeout-dialog/session-timeout-dialog.component';
import { ColorPickerDialogComponent } from './components/color-picker-dialog/color-picker-dialog.component';
import { InfoTitleComponent } from 'app/shared/components/info-title/info-title.component';
import { AccordionComponent } from './components/accordion/accordion.component';
import { PdfPreviewDialogComponent } from 'app/shared/components/pdf-preview-dialog/pdf-preview-dialog.component';
import { MobilePhoneEntryComponent } from 'app/shared/components/mobile-phone-entry/mobile-phone-entry.component';
import { SegmentedButtonComponent } from './components/segmented-button/segmented-button.component';
import { SegmentedButtonGroupComponent } from './components/segmented-button-group/segmented-button-group.component';
import { SkeletonLoaderComponent } from './components/skeleton-loader/skeleton-loader.component';
import { ColorInputComponent } from './components/color-input/color-input.component';
import { CloneClientOwnedItemDialogComponent } from 'app/shared/components/clone-client-owned-item-dialog/clone-client-owned-item-dialog.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { FolderComponent } from './components/folder/folder.component';
import { CopyButtonComponent } from './components/copy-button/copy-button.component';
import { NegativePositiveInputComponent } from './components/negative-positive-input/negative-positive-input.component';
import { TileGroupComponent } from './components/tile-group/tile-group.component';
import { TileComponent } from './components/tile/tile.component';
import { EntityStatusComponent } from './components/entity-status/entity-status.component';
import { EntityMergedStatusComponent } from './components/entity-merged-status/entity-merged-status.component';
import { EntityContactCardComponent } from './components/entity-contact-card/entity-contact-card.component';
import { EntityApprovalStatusComponent } from './components/entity-approval-status/entity-approval-status.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { PostcodeLookupComponent } from 'app/shared/components/postcode-lookup/postcode-lookup.component';
import { ImageDialogComponent } from './components/image-dialog/image-dialog.component';
import { RouteDisabledDialogComponent } from './components/route-disabled-dialog/route-disabled-dialog.component';

// Directives
import { ModuleVisibleDirective } from './directives/module-visible.directive';
import { AutoFocusDirective } from './directives/auto-focus.directive';
import { DisableControlDirective } from './directives/disable-control.directive';
import { EmptyToNullDirective } from './directives/empty-to-null.directive';
import { FileDropDirective } from './directives/file-drop.directive';
import { SkeletonLoaderDirective } from './directives/skeleton-loader.directive';
import { TrackTransitionDirective } from './directives/track-transition.directive';
import { TrackHoverDirective } from './directives/track-hover.directive';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { ReplaceWithTextIfEmptyDirective } from './directives/replace-with-text-if-empty.directive';

// Modules
import { PlatformMaterialModule } from './platform-material.module';
import { ClipboardModule } from 'ngx-clipboard';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TranslateModule } from '@ngx-translate/core';
import { Ng2FittextModule } from 'ng2-fittext';
import { ReactiveFormsModule } from '@angular/forms';
import { PinchZoomModule } from '@meddv/ngx-pinch-zoom';

// Pipes
import { SafePipe, SortCodeFormatPipe, OrderByPipe, UkDatePipe } from './pipes';
import { IsItemTypePipe } from './pipes/is-item-type.pipe';
import { IsMatchingStringPipe } from './pipes/is-matching-string.pipe';
import { IsItemTypeListPipe } from './pipes/is-item-type-list.pipe';
import { IsItemTypeRTWPipe } from './pipes/is-item-show-label.pipe';
import { ModuleIsEnabledPipe } from './pipes/module-is-enabled.pipe';
import { ModuleTitlePipe } from './pipes/module-title.pipe';
import { MobileNumberPipe } from './pipes/mobile-number.pipe';
import { EnumTransformerPipe } from './pipes/enum-transformer.pipe';
import { FormArrayPipe } from './pipes/form-array-pipe';
import { NamePipe } from './pipes/name.pipe';
import { JoinToStringPipe } from './pipes/join-to-string.pipe';
import { AddressPipe } from './pipes/address.pipe';
import { MonthPipe } from './pipes/month.pipe';


// Services
import { AlertService } from './components/alert/services/alert.service';
import { ValidationService, DeviceDetectionService, PdfViewerService } from './services';
import { ExternalReferencesService } from './services/external-references.service';
import { ImageUploadService } from './services/image-upload.service';
import { VideoCaptureService } from './services/video-capture.service';
import { EventTrackingService } from './services/event-tracking.service';
import { ModuleService } from './services/module.service';
import { PagingService } from './services/paging.service';
import { HandlebarsService } from './services/handlebars.service';
import { GoogleTagManagerService } from './services/google-tag-manager.service';
import { NavigationService } from './services/navigation.service';
import { FileUploadService } from 'app/shared/services/file-upload.service';
import { SessionStateService } from 'app/shared/services/session-state.service';
import { PostcodeService } from 'app/shared/services/postcode.service';

// Extensions
import 'app/shared/extensions/date.extension';
import 'app/shared/extensions/array.extension';


const components = [
    ColorPickerComponent,
    LoadingCardComponent,
    AlertComponent,
    FileUploadComponent,
    ImageUploadComponent,
    PhotoCaptureComponent,
    UsageDialogComponent,
    PageNotFoundComponent,
    ConfirmationRequiredDialogComponent,
    TrueFalseTickIconComponent,
    DateInputComponent,
    DialogComponent,
    DialogFieldComponent,
    DialogFieldInformationComponent,
    PagingNavigationComponent,
    CardContainerComponent,
    WarningContainerComponent,
    SectionWrapperComponent,
    NoInformationMessageComponent,
    InformationDialogComponent,
    SupportTextComponent,
    AdviceTextComponent,
    PageInformationComponent,
    DisableControlDirective,
    ImagePopupComponent,
    SecureBackgroundImageComponent,
    SecureImageComponent,
    SecureBackgroundImageComponent,
    ImageCardComponent,
    FileUploadDialogComponent,
    DtoUploadDialogComponent,
    SignatureDialogComponent,
    CanvasComponent,
    ActivityLogComponent,
    CircleProgressComponent,
    SearchableInputComponent,
    SearchableMultiSelectInputComponent,
    SessionTimeoutDialogComponent,
    ColorPickerDialogComponent,
    InfoTitleComponent,
    AccordionComponent,
    PdfPreviewDialogComponent,
    MobilePhoneEntryComponent,
    SegmentedButtonComponent,
    SegmentedButtonGroupComponent,
    SkeletonLoaderComponent,
    ColorInputComponent,
    CloneClientOwnedItemDialogComponent,
    CarouselComponent,
    FolderComponent,
    CopyButtonComponent,
    NegativePositiveInputComponent,
    TileGroupComponent,
    TileComponent,
    EntityApprovalStatusComponent,
    EntityStatusComponent,
    EntityMergedStatusComponent,
    EntityContactCardComponent,
    SpinnerComponent,
    PostcodeLookupComponent,
    ImageDialogComponent,
    RouteDisabledDialogComponent
];

const pipes = [
    SafePipe,
    SortCodeFormatPipe,
    OrderByPipe,
    IsItemTypePipe,
    IsMatchingStringPipe,
    IsItemTypeListPipe,
    IsItemTypeRTWPipe,
    UkDatePipe,
    ModuleIsEnabledPipe,
    ModuleTitlePipe,
    MobileNumberPipe,
    EnumTransformerPipe,
    FormArrayPipe,
    NamePipe,
    JoinToStringPipe,
    AddressPipe,
    MonthPipe
];

const directives = [
    ModuleVisibleDirective,
    AutoFocusDirective,
    EmptyToNullDirective,
    FileDropDirective,
    SkeletonLoaderDirective,
    TrackTransitionDirective,
    TrackHoverDirective,
    ClickOutsideDirective,
    ReplaceWithTextIfEmptyDirective
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        PlatformMaterialModule,
        ReactiveFormsModule,
        ClipboardModule,
        TranslateModule,
        DragDropModule,
        Ng2FittextModule,
        ColorPickerModule,
        NgxExtendedPdfViewerModule,
        PinchZoomModule,
        NgxMaskDirective,
        NgxMaskPipe,
        LazyLoadImageModule,
        InfiniteScrollModule,
    ],
    declarations: [
        ...components,
        ...pipes,
        ...directives
    ],
    providers: [
        ExternalReferencesService,
        AlertService,
        ValidationService,
        DeviceDetectionService,
        ImageUploadService,
        VideoCaptureService,
        PdfViewerService,
        EventTrackingService,
        ModuleService,
        PagingService,
        HandlebarsService,
        GoogleTagManagerService,
        NavigationService,
        FileUploadService,
        provideNgxMask(),
        SessionStateService,
        PostcodeService,
    ],
    exports: [
        ...components,
        ...pipes,
        ...directives,
        ReactiveFormsModule,
        PlatformMaterialModule,
        TranslateModule,
        RouterModule,
        NgxMaskDirective,
        NgxMaskPipe,
        LazyLoadImageModule,
        InfiniteScrollModule
    ]
})

export class SharedModule { }