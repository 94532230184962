import { Pipe, PipeTransform } from '@angular/core';

// utilities
import { joinToString } from 'app/shared/utilities/string-utilities';

// models
import { Address } from 'app/portal/modules/inputs/models/address.model';
import { Address as AddressVerifier } from 'app/back-office/modules/verifier/models/address.model';
import { DataCheckGbgID3Input } from 'app/portal/modules/data-checks/models/gbg-id3.model';
import { CompanySummary } from 'app/portal/modules/company-list/models/company-summary.model';
import { CompanyDetail } from 'app/portal/modules/company-list/models/company-detail.model';
import { Officer } from 'app/portal/modules/company-list/models/officer.model';
import { SourceOfFundsDetails } from 'app/portal/modules/source-of-funds/models/source-of-funds-details.model';

@Pipe({ name: 'address' })
export class AddressPipe implements PipeTransform {
    transform(
        address:
            | Address
            | DataCheckGbgID3Input
            | AddressVerifier
            | CompanySummary
            | CompanyDetail
            | Officer 
            | SourceOfFundsDetails
    ): string {
        if (!address) {
            return '';
        }

        const addressParts = [];

        if ('addressLine1' in address && 'premises' in address) {
            addressParts.push(`${address.premises} ${address.addressLine1}`);
        } else if ('addressLine1' in address) {
            addressParts.push(address.addressLine1);
        }

        if ('addressLine2' in address) {
            addressParts.push(address.addressLine2);
        }

        if ('locality' in address) {
            addressParts.push(address.locality);
        }

        if ('town' in address) {
            addressParts.push(address.town);
        }

        if ('city' in address) {
            addressParts.push(address.city);
        }

        if ('region' in address) {
            addressParts.push(address.region);
        }

        if ('county' in address) {
            addressParts.push(address.county);
        }

        if ('postcode' in address) {
            addressParts.push(address.postcode);
        }

        if ('country' in address) {
            addressParts.push(address.country);
        }

        return joinToString(addressParts, ',', ',');
    }
}
